import React, { Component } from 'react';
import config from './../../settings/config';
import Rest from './../../Server/Rest';
import Parse from 'parse';
import user from './../../settings/user';
import Spinner from './../others/Spinner';
import GroupForm from './GroupForm';
import GroupRegister from './GroupRegister';
import RequisitionModel from '../../models/RequisitionModel';
import $ from 'jquery';
import _ from 'underscore';
import textUtils from '../../utilities/format-string';
import { addSolicitud, saveAmounts, setAmounts } from '../../services/requisition';

class GroupEdit extends GroupRegister {
    constructor(props){
        super(props);
        this.state = this.state_init();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRemovePartner = this.handleRemovePartner.bind(this);
        this.handleEndCycle = this.handleEndCycle.bind(this);
        this.handleChangeStatusSocios = this.handleChangeStatusSocios.bind(this);
    }

    state_init = () => {

        this.data = {};
        this.data_original = {};
        this.Sucursal = {};
        this.current_requisition = {};
        this.activeNewCycle = false;
        this.partnersStatics = [];

        return {
            resetform: false,
            loading: false,
            readOnly: true,
            grupo_reducido: false,
            status: '',
            status_comentario: '',
            status_socios: [],
            partnerRemove: {},
            partnerRemoveStatus: '',
            partnerRemoveStatus_comentario: '',
            cuentas_bancarias: [{
                Socio: {
                    __type: 'Pointer',
                    className: 'Socios',
                    objectId: ''
                },
                tipo: '',
                banco: '',
                cuenta: '',
                titular: ''
            }]
        }
    }


    handleSpinner = () => {
        this.setState(state => ({
            loading: !state.loading
        }));
    }

    handleChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleChangeEdit = (event) => {
        let target = event.target;
        let value = target.value;
        let name = target.name;
        
        if (this.state[name] === false) {
            this.getGroup();
        }
        
        this.setState((state) => ({
            [name]: !state[name]
        }));

    }

    setData = (data) => {
        this.data = data;
    }

    getGroup = () => {

        this.handleSpinner();

        const params = new URLSearchParams({
            excludeKeys: 'registeredBy',
            include: 'Sucursal,Socios,Solicitudes,Solicitudes.montos'
        });
        
        Rest.peticion(`${config.api_url}/classes/Grupos/${this.props.match.params.id_grupo}?${params.toString()}`, {
            method: 'GET'
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            if (res.code) {
                alert('Ocurrió un error al consultar los datos. Intente más tarde o contacte a soporte.');
            } else {
                this.partnersStatics        = _.clone(res.Socios);
                this.data_original          = _.clone(res);
                this.data                   = _.clone(res);
                this.current_requisition    = res.Solicitudes[res.Solicitudes.length -1];
                
                let newState = {};
                newState.resetform = true;
                newState.grupo_reducido = res.grupo_reducido;
                newState.status_socios = _.map(res.Socios, socio => {
                    return {
                        objectId: socio.objectId,
                        status: socio.status,
                        status_comentario: ''
                    }
                })

                newState.cuentas_bancarias = res.Solicitudes[res.Solicitudes.length -1].cuentas_bancarias || [{
                    Socio: {
                        __type: 'Pointer',
                        className: 'Socios',
                        objectId: ''
                    },
                    tipo: '',
                    banco: '',
                    cuenta: '',
                    titular: ''
                }];

                this.setState({...this.state, ...newState});
                setTimeout(() => {
                    this.setState({
                        resetform: false
                    });
                }, 200);
            }
        })
        .catch(error => {
            this.handleSpinner();
            console.error('Ocurrió un error al consultar los datos. Intente más tarde o contacte a soporte.', error);
            alert('Ocurrió un error al consultar los datos. Intente más tarde o contacte a soporte.');
        })
        .finally(() => {
            setTimeout(() => {
                this.handleSpinner();
            }, 1000);
        });
    }

    // consultar datos del socio
    UNSAFE_componentWillMount = async () => {
        this.getGroup();
    }

    setRemovePartner = (partner) => {
        if (this.current_requisition && this.current_requisition.status === 'autorizado') {
            if (window.confirm('La solicitud actual de la clienta está autorizada, ¿Confirma que desea retirar a la clienta del grupo actual?')) {    
                this.setState({
                    partnerRemove: partner,
                    status: '',
                    status_comentario: ''
                });
                $('#removePartnerModal').modal('show');
            }
        } else {
            this.setState({
                partnerRemove: partner,
                status: '',
                status_comentario: ''
            });
            $('#removePartnerModal').modal('show');
        }
    }

    saveRelationHistorial = async (id_busqueda, historial) => {

        let data = {
            id_busqueda: id_busqueda
        };

        if (historial) {
            data.historial = {
                __op: 'AddRelation',
                objects: [{
                    __type: 'Pointer',
                    className: 'GruposHistorial',
                    objectId: historial.objectId
                }]
            }
        }
        
        return Rest.peticion(`${config.api_url}/classes/GruposDiccionario/${this.props.match.params.id_diccionario}`, {
            method: 'PUT',
            body: JSON.stringify(data)
        })
        .then(res => {
            return res.json();
        })
        .then(res => {
            return res;
        })
        .catch(error => {
            return error;
        });
    }

    saveData = async (data) => {
        return Rest.peticion(`${config.api_url}/classes/Grupos/${this.props.match.params.id_grupo}`, {
            method: 'PUT',
            body: JSON.stringify(data)
        }).then(res => {return res.json()}).then(res => {return res}).catch(error => {return error});
    }

    setActiveNewCycle = (value) => {
        this.activeNewCycle = value;
    }

    whereValidName = () => {
        return {
            Grupo: {
                $select: {
                    query: {
                        className: 'Grupos',
                        where: {
                            nombre: this.data.nombre.toUpperCase().trim(),
                            Sucursal: {
                                __type: 'Pointer',
                                className: 'Sucursales',
                                objectId: this.data.Sucursal
                            },
                            objectId: {
                                $ne: this.props.match.params.id_grupo
                            }
                        }
                    },
                    key: 'objectId'
                }
            },
            
        }
    }

    
    handleSubmit = async (event) => {

        if (event) event.preventDefault();

        if (user.rol.es_administrador === true) {
            await this.handleValidName();
            if (this.group_name_exist === true) {
                return;
            }
        }


        if(this.validarPrestamoPersonalizado())
        {
            return;
        }

        if(this.validarMaster())
        {
            return;
        }

        if((this.data.prestamo_personalizado === false && !this.data.prestamo_personalizado_interes) && this.data.Socios.length < 3) {
            alert('Se requiere por lo menos 3 clientas para conformar el grupo.');
            return;
        }

        if (
            (this.validateAmounts('monto_solicitado') || this.validateAmounts('monto_sugerido')) &&
            !(this.current_requisition.status === 'rechazado' || this.current_requisition.status === 'baja')
        ) {
            alert('Debe especificar los montos solicitados y sugeridos antes de guardar.');
            return;
        }

        const r = window.confirm('¿Todos los datos son correctos?');

        if (r === true) {
        
            let data = _.clone(this.data);
            
            if (data.modo_desembolso === 'EFECTIVO') {
                data.responsable_tdd_banco     = {__op:'Delete'};
                data.responsable_tdd_numero    = {__op:'Delete'};
                data.responsable_tdd_titular   = {__op:'Delete'};
                data.responsable_clabe_banco     = {__op:'Delete'};
                data.responsable_clabe    = {__op:'Delete'};
                data.responsable_clabe_titular   = {__op:'Delete'};
            } else if (data.modo_desembolso === 'TARJETA_DEBITO') {
                data.responsable_tdd_banco     = data.responsable_tdd_banco;
                data.responsable_tdd_numero    = data.responsable_tdd_numero;
                data.responsable_tdd_titular   = data.responsable_tdd_titular;
            } else if (data.modo_desembolso === 'CLABE') {
                data.responsable_clabe_banco     = data.responsable_clabe_banco;
                data.responsable_clabe    = data.responsable_clabe;
                data.responsable_clabe_titular   = data.responsable_clabe_titular;
            }

            data.Socios                    = data.Socios;
            
            data.promotor = {
                __type: 'Pointer',
                className: 'Empleados',
                objectId: data.promotor
            };
            
            data.responsable = {
                __type: 'Pointer',
                className: 'Socios',
                objectId: data.responsable
            };

            const toDay = new Date();
            data.fecha_elaboracion.setHours( toDay.getHours() );
            data.fecha_elaboracion.setMinutes( toDay.getMinutes() );
            data.fecha_elaboracion = {
                __type: 'Date',
                iso: data.fecha_elaboracion.toISOString()
            }
            
            data.Socios = _.map(data.Socios, objectId => {
                return {
                    __type: 'Pointer',
                    className: 'Socios',
                    objectId: objectId
                }
            });
            
            // formatear datos
            _.map(data, (value, key) => {
                let newValue;
                
                if (!_.isEmpty(value) && _.isString(value)) {
                    switch (key) {
                        case 'Sucursal':
                            newValue = {
                                __type: 'Pointer',
                                className: 'Sucursales',
                                objectId: value
                            };
                            break;
                        case 'dia_pago':
                            newValue = parseInt(value);
                            break;
                        case 'montos':
                            newValue = _.map(value, (item) => {
                                item.monto_actual = parseInt(item.monto_actual);
                                item.monto_solicitado = parseInt(item.monto_solicitado);
                                item.monto_sugerido = parseInt(item.monto_sugerido);
                                return item;
                            });
                            break;
                        case 'responsable_tdd_numero':
                            newValue = value.replace(/ /g, "");
                            break;
                        case 'status':
                        case 'charge_factor_type':
                            newValue = value;
                            break;
                        default:
                            newValue = value.toUpperCase().trim();
                            break;
                    }
                } else if (_.isObject(value)) {
                    switch (key) {
                        case 'aval':
                            newValue = {
                                nombre: value.nombre.toUpperCase(),
                                apellido_paterno: value.apellido_paterno.toUpperCase(),
                                apellido_materno: value.apellido_materno.toUpperCase(),
                                domicilio_direccion: value.domicilio_direccion.toUpperCase(),
                                domicilio_cp: value.domicilio_cp.toUpperCase(),
                                domicilio_entidad: value.domicilio_entidad.toUpperCase(),
                                domicilio_poblacion: value.domicilio_poblacion.toUpperCase(),
                                domicilio_colonia: value.domicilio_colonia.toUpperCase()
                            };
                            break;
                        default:
                            newValue = value;
                            break;
                    }
                } else {
                    if (!_.isBoolean(value) && _.isEmpty(value) && !_.isNumber(value)) {
                        newValue = {__op:'Delete'};
                    } else {
                        newValue = value;
                    }
                }

                data[key] = newValue;
            });
            
            if (
                data.modo_desembolso === 'TARJETA_DEBITO' &&
                data.responsable_tdd_numero.length < 16
            ) {
                alert('El Número de tarjeta no es correcto. corrija e intente de nuevo.');
                return;
            };
            if (
                data.modo_desembolso === 'CLABE' &&
                data.responsable_clabe.length < 18
            ) {
                alert('El Número de Clabe no es correcto. corrija e intente de nuevo.');
                return;
            };


            const amounts = _.clone(data.montos);
            const ciclo = data.ciclo;
            const dia_pago = parseInt(data.dia_pago);
            const cantidad_pagos_semanales = parseInt(data.cantidad_pagos_semanales);
            const fecha_elaboracion = data.fecha_elaboracion;
            const prestamo_personalizado = data.prestamo_personalizado;
            const prestamo_personalizado_cantidad_pagos = parseInt(data.prestamo_personalizado_cantidad_pagos);
            const prestamo_personalizado_periodicidad = parseInt(data.prestamo_personalizado_periodicidad);
            const prestamo_personalizado_interes = parseFloat(data.prestamo_personalizado_interes);
            const garantia = data.garantia;
            const garantia_valor = data.garantia_valor;
            const cuentas_bancarias = data.cuentas_bancarias;
            let meses = undefined;
            let interes = undefined;
            let iva_del_interes = undefined;

            const fc = parseFloat(data.factor_cobro);
            // si es falso entonces es una edición, por lo que no se enviarán datos para la solicitud actual
            // if (this.activeNewCycle === true) {
                // charge_factor_type = RequisitionModel.setChargeFactorType(prestamo_personalizado_periodicidad, this.current_requisition.charge_factor_type, garantia, prestamo_personalizado);
                // factor_cobro = prestamo_personalizado ? RequisitionModel[charge_factor_type][data.factor_cobro].charge_factor : data.factor_cobro;
            // } else {
                const charge_factor_type = RequisitionModel.setChargeFactorType(prestamo_personalizado_periodicidad, undefined, garantia, prestamo_personalizado);
                const factor_cobro = prestamo_personalizado === true ? 
                    (
                        RequisitionModel[charge_factor_type][fc] ? 
                        RequisitionModel[charge_factor_type][fc].charge_factor : 
                        fc
                    ) : 
                    fc;
            // }
            // if (!(prestamo_personalizado === true) && charge_factor_type) {
            //     meses = RequisitionModel[charge_factor_type][data.factor_cobro].meses;
            //     interes = RequisitionModel[charge_factor_type][data.factor_cobro].interes;
            //     iva_del_interes =RequisitionModel[charge_factor_type][data.factor_cobro].iva_del_interes;
            // }
            
            delete data.montos;
            delete data.ciclo;
            delete data.dia_pago;
            delete data.cantidad_pagos_semanales;
            delete data.fecha_elaboracion;
            delete data.factor_cobro;
            delete data.prestamo_personalizado;
            delete data.prestamo_personalizado_cantidad_pagos;
            delete data.prestamo_personalizado_periodicidad;
            delete data.prestamo_personalizado_interes;
            delete data.garantia;
            delete data.garantia_valor;
            delete data.cuentas_bancarias;
            try {
                delete data.grupo;
            } catch (error) {}

            let groupId = this.props.match.params.id_grupo;

            if (this.state.grupo_reducido === true) {
                data.grupo_reducido = true;
            } else {
                data.grupo_reducido = false;
            }

            if (this.activeNewCycle === true) {
                data.status = 'autorizacion';
            }

            if (user.rol.es_administrador !== true) {
                delete data.nombre;
            } else {
                data.id_busqueda = textUtils.setIdBusqueda(data.nombre);
            }


            this.handleSpinner();

            return Rest.peticion(`${config.api_url}/classes/Grupos/${this.props.match.params.id_grupo}`, {
                method: 'PUT',
                body: JSON.stringify(data)
            }).then(res => {

                return res.json();

            }).then(async res => {

                if (this.activeNewCycle === true) {

                    const _amounts = setAmounts(amounts, groupId);

                    return await saveAmounts(_amounts);
                } else {
                    return res;
                }

            }).then(async res => {
                if (this.activeNewCycle === true) {
                    return await this.saveSolicitud(
                        res, 
                        groupId, 
                        data.Sucursal, 
                        dia_pago, 
                        ciclo, 
                        cantidad_pagos_semanales, 
                        fecha_elaboracion, 
                        factor_cobro,
                        prestamo_personalizado,
                        prestamo_personalizado_cantidad_pagos,
                        prestamo_personalizado_periodicidad,
                        prestamo_personalizado_interes,
                        garantia,
                        garantia_valor,
                        cuentas_bancarias,
                        meses,
                        interes,
                        iva_del_interes,
                        charge_factor_type
                    );
                } else {
                    return res;
                }
            }).then(res => {
                if (this.activeNewCycle === true) {
                    return res.json();
                } else {
                    return res;
                }
            }).then(async res => {
                if (this.activeNewCycle === true) {
                    return await addSolicitud(res.objectId, groupId);
                } else {
                    return res;
                }
            }).then(res => {
                if (res.code || res.error) {
                    alert('No se ha podido actualizar los datos del grupo. Intente más tarde.');
                    return res;
                } else if (
                    this.current_requisition.status !== 'baja' &&
                    this.current_requisition.status !== 'rechazado'
                ) {
                    return Rest.peticion(`${config.api_url}/classes/Solicitudes/${this.current_requisition.objectId}`, {
                        method: 'PUT',
                        body: JSON.stringify({
                            promotor: {
                                __type: 'Pointer',
                                className: 'Empleados',
                                objectId: data.promotor.objectId
                            },
                            responsable: {
                                __type: 'Pointer',
                                className: 'Socios',
                                objectId: data.responsable.objectId
                            },
                            dia_pago: dia_pago,
                            cantidad_pagos_semanales: cantidad_pagos_semanales || {__op:'Delete'},
                            fecha_elaboracion: fecha_elaboracion,
                            factor_cobro: factor_cobro || {__op:'Delete'},
                            prestamo_personalizado: prestamo_personalizado || {__op:'Delete'},
                            prestamo_personalizado_cantidad_pagos: prestamo_personalizado_cantidad_pagos || {__op:'Delete'},
                            prestamo_personalizado_periodicidad: prestamo_personalizado_periodicidad || {__op:'Delete'},
                            prestamo_personalizado_interes: prestamo_personalizado_interes || {__op:'Delete'},
                            garantia: garantia || {__op:'Delete'},
                            garantia_valor: (!_.isEmpty(garantia) && garantia_valor) || {__op:'Delete'},
                            cuentas_bancarias: (this.data.modo_desembolso === 'BANCO' && this.validCuentasBancarias(cuentas_bancarias) )|| {__op:'Delete'},
                            meses: meses,
                            interes: interes,
                            iva_del_interes: iva_del_interes,
                            charge_factor_type: charge_factor_type,
                            Sucursal: data.Sucursal,
                        })
                    }).then(_res => {
                        if (_res.code || _res.error) {
                            // console.log(_res);
                        } else {
                            alert('Los datos del grupo han sido actualizados.');
                        }
                        return _res;
                    }).catch(_error => {
                        return _error;
                    });
                } else {
                    alert('Los datos del grupo han sido actualizados.');
                    return res;
                }
            }).then(res => {
                if (this.activeNewCycle === true) {
                    this.activeNewCycle = false;
                }
                this.setState({
                    loading: false,
                    resetform: true
                });
                setTimeout(() => {
                    this.setState({
                        resetform: false
                    });
                    this.getGroup();
                }, 200);
                return res;
            }).then(res => {
                if (user.rol.es_administrador === true) {
                    Rest.peticion(`${config.api_url}/classes/GruposDiccionario/${this.props.match.params.id_diccionario}`, {
                        method: 'PUT',
                        body: JSON.stringify({
                            id_busqueda: data.id_busqueda
                        })
                    }).then(res => {
                        return res;
                    }).then(res => {
                        // id_busqueda guardado correctamente
                    }).catch(error => {
                        console.log(error);
                    })
                }
            }).catch(error => {
                console.log(error);
                alert('No se ha podido actualizar los datos del grupo. Intente más tarde.');
                this.handleSpinner();
            });
        }

        

    }

    handleRemovePartner = (event) => {
        
        if (event) event.preventDefault();

        const r = window.confirm('¿Está seguro de quitar la clienta del grupo actual?');

        if (r === true) {
            const data = {
                requests: [{
                    method: 'PUT',
                    path: `${config.api_path2}/classes/Socios/${this.state.partnerRemove.objectId}`,
                    body: {
                        status: this.state.status,
                        status_comentario: this.state.status_comentario
                    }
                },{
                    method: 'PUT',
                    path: `${config.api_path2}/classes/Grupos/${this.props.match.params.id_grupo}`,
                    body: {
                        Socios: {
                            __op: 'Remove',
                            objects: [{
                                __type: 'Pointer',
                                className: 'Socios',
                                objectId: this.state.partnerRemove.objectId
                            }]
                        }
                    }
                }]
            }

            if (
                this.current_requisition.status === 'autorizacion' ||
                this.current_requisition.status === 'autorizado'
            ) {
                _.each(this.current_requisition.montos, monto => {
                    if (monto.Socio.objectId === this.state.partnerRemove.objectId) {
                        data.requests.push({
                            method: 'PUT',
                            path: `${config.api_path2}/classes/Solicitudes/${this.current_requisition.objectId}`,
                            body: {
                                montos: {
                                    __op: 'Remove',
                                    objects: [{
                                        __type: 'Pointer',
                                        className: 'SolicitudesMontos',
                                        objectId: monto.objectId
                                    }]
                                }
                            }
                        })
                    }
                });
            }

            this.handleSpinner();
            
            Rest.peticion(`${config.api_url}/batch`, {
                method: 'POST',
                body: JSON.stringify(data)
            }).then(res => {
                return res.json();
            }).then(res => {
                this.handleSpinner();
                $('#removePartnerModal').modal('hide');
                alert('La clienta ha sido retirada del grupo.');
                this.state_init();
                this.getGroup();
            }).catch(error => {
                this.handleSpinner();
                console.log(error);
            });
        }
    }

    handleRemovePartnerAfterCycle1 = (partner) => {
        const r = window.confirm('¿Está seguro de quitar la clienta del grupo actual?');

        if (r === true) {
            const data = {
                requests: [{
                    method: 'PUT',
                    path: `${config.api_path2}/classes/Grupos/${this.props.match.params.id_grupo}`,
                    body: {
                        Socios: {
                            __op: 'Remove',
                            objects: [{
                                __type: 'Pointer',
                                className: 'Socios',
                                objectId: partner.objectId
                            }]
                        }
                    }
                }]
            }

            if (this.current_requisition.status === 'autorizacion') {
                _.each(this.current_requisition.montos, monto => {
                    if (monto.Socio.objectId === partner.objectId) {
                        data.requests.push({
                            method: 'PUT',
                            path: `${config.api_path2}/classes/Solicitudes/${this.current_requisition.objectId}`,
                            body: {
                                montos: {
                                    __op: 'Remove',
                                    objects: [{
                                        __type: 'Pointer',
                                        className: 'SolicitudesMontos',
                                        objectId: monto.objectId
                                    }]
                                }
                            }
                        })
                    }
                });
            }
            
            
            Rest.peticion(`${config.api_url}/batch`, {
                method: 'POST',
                body: JSON.stringify(data)
            }).then(res => {
                this.getGroup();
                return res.json();
            }).then(res => {
                alert('La clienta ha sido retirada del grupo.');
            }).catch(error => {
                console.log(error);
            });
        }
    }

    handleOpenEndCycle = () => {
        $('#modaEndCycle').modal('show');
    }

    handleActiveGroup = () => {
        if (!window.confirm('¿Confirma que desea activar el grupo?')) {
            return;
        }

        this.setState({
            loading: true
        });
        Rest.peticion(`${config.api_url}/classes/Grupos/${this.props.match.params.id_grupo}`, {
            method: 'PUT',
            body: JSON.stringify({
                status: 'activo'
            })
        }).then(res => {
            return res.json()
        }).then(res => {
            if (res.code || res.error) {
                alert('No se ha podido activar el grupo, intente de nuevo más tarde.');
            } else {
                alert('El grupo ha sido activado');
                this.getGroup();
            }
            this.setState({
                loading: true
            });
        }).catch(error => {
            console.log(error);
            alert('No se ha podido activar el grupo, intente de nuevo más tarde.');
        });
    }

    handleEndCycle = (event) => {

        if (event) event.preventDefault();

        const r = window.confirm('ADVERTENCIA. ¿Confirma que desea dar de baja el ciclo actual?');
        
        if (r === true) {

            const currentUser = Parse.User.current().toJSON();

            const fecha_actual = {
                __type: 'Date',
                iso: new Date().toISOString()
            };

            let requests = [];

            requests.push({
                method: 'PUT',
                path: `${config.api_path2}/classes/Grupos/${this.props.match.params.id_grupo}`,
                body: {
                    status: this.state.status,
                    status_comentario: _.isEmpty(this.state.status_comentario) ? {__op:'Delete'} : this.state.status_comentario.toUpperCase(),
                }
            });

            requests.push({
                method: 'PUT',
                path: `${config.api_path2}/classes/Solicitudes/${this.current_requisition.objectId}`,
                body: {
                    fecha_finalizacion: fecha_actual,
                    finalizado_por: {
                        __type: 'Pointer',
                        className: '_User',
                        objectId: currentUser.objectId
                    },
                    status: 'baja',
                    status_comentario: _.isEmpty(this.state.status_comentario) ? {__op:'Delete'} : this.state.status_comentario.toUpperCase(),
                }
            });

            _.each(this.state.status_socios, socio => {
                socio.status_comentario = socio.status_comentario.toUpperCase();
                requests.push({
                    method: 'PUT',
                    path: `${config.api_path2}/classes/Socios/${socio.objectId}`,
                    body: {
                        status: socio.status,
                        status_comentario: socio.status_comentario
                    }
                });
                let old_socio = _.findWhere(this.data_original.Socios, {objectId: socio.objectId});
                
                let _data_status = {};

                old_socio.status = old_socio.status.toUpperCase();
                if (!_.isEqual(old_socio.status, socio.status)) {
                    _data_status.status = old_socio.status;
                }

                old_socio.status_comentario = old_socio.status_comentario.toUpperCase();
                if (!_.isEqual(old_socio.status_comentario, socio.status_comentario)) {
                    _data_status.status_comentario = old_socio.status_comentario;
                }

                if (!_.isEmpty(_data_status)) {
                    requests.push({
                        method: 'POST',
                        path: `${config.api_path2}/classes/SociosHistorial`,
                        body: {
                            datos_anteriores: _data_status,
                            afectado_por: currentUser.nombre+' '+currentUser.apellidos,
                            usuario_email: currentUser.email,
                            usuario_telefono: currentUser.telefono,
                            grupo_datos: 'datos-personales',
                            Rol: {
                                __type: 'Pointer',
                                className: 'Roles',
                                objectId: user.rol.objectId
                            },
                            User: {
                                __type: 'Pointer',
                                className: '_User',
                                objectId: currentUser.objectId
                            },
                            Socio: {
                                __type: 'Pointer',
                                className: 'Socios',
                                objectId: socio.objectId
                            }
                        }
                    })
                }
                
            });

            this.handleSpinner();

            Rest.peticion(`${config.api_url}/batch`, {
                method: 'POST',
                body: JSON.stringify({
                    requests: requests
                })
            })
            .then(res => {
                this.handleSpinner();
                return res.json();
            })
            .then(res => {
                if (res.code || res.error) {
                    alert('Ha ocurrido un error al intentar finalizar el ciclo');
                } else {
                    $('#modaEndCycle').modal('hide');
                    setTimeout(() => {
                        this.setState(_.extend(this.state_init(), {readOnly: false}));
                        this.getGroup();
                        alert('El ciclo ha sido dado de baja');
                    }, 200);
                }
            })
            .catch(error => {
                this.handleSpinner();
                return error;
            });
        }

    }

    // finaliza un ciclo sin solicitar la modificación de status al asesor
    handleRestructure = (event) => {

        if (event) event.preventDefault();

        const r = window.confirm('ADVERTENCIA. esta acción finalizará el ciclo actual');
        
        if (r === true) {

            const currentUser = Parse.User.current().toJSON();

            const fecha_actual = {
                __type: 'Date',
                iso: new Date().toISOString()
            };

            let requests = [];

            requests.push({
                method: 'PUT',
                path: `${config.api_path2}/classes/Grupos/${this.props.match.params.id_grupo}`,
                body: {
                    status: 'reestructurado',
                }
            });

            requests.push({
                method: 'PUT',
                path: `${config.api_path2}/classes/Solicitudes/${this.current_requisition.objectId}`,
                body: {
                    fecha_finalizacion: fecha_actual,
                    finalizado_por: {
                        __type: 'Pointer',
                        className: '_User',
                        objectId: currentUser.objectId
                    },
                    status: 'baja'
                }
            });

            this.handleSpinner();

            Rest.peticion(`${config.api_url}/batch`, {
                method: 'POST',
                body: JSON.stringify({
                    requests: requests
                })
            })
            .then(res => {
                this.handleSpinner();
                return res.json();
            })
            .then(res => {
                if (res.code || res.error) {
                    alert('Ha ocurrido un error al intentar finalizar el ciclo');
                } else {
                    setTimeout(() => {
                        this.setState(_.extend(this.state_init(), {readOnly: false}));
                        this.getGroup();
                        alert('El ciclo ha sido dado de baja');
                    }, 200);
                }
            })
            .catch(error => {
                this.handleSpinner();
                return error;
            });
        }

    }

    handleChangeStatusSocios = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        const name_split = name.split(',');
        let {status_socios} = this.state;
        status_socios[name_split[1]][name_split[0]] = value;
        
        this.setState({
            status_socios: status_socios
        });
    }

    render() {
        return (
            <>
                <Spinner loading={this.state.loading} />

                {this.data_original.foto_grupal_url && <>
                    <div className="d-flex align-items-center justify-content-center mb-4 rounded overflow-hidden bg-light" alt="Foto grupal" style={styles.contentImgGroup}>
                        <img src={config.domain+this.data_original.foto_grupal_url} className="img-fluid animate__animated animate__fadeIn" />
                    </div>
                </>}

                {(user.rol.permisos.Grupos && user.rol.permisos.Grupos.editar) &&
                <div className="row">
                    <div className="col-8">
                        {!this.state.readOnly && <p className="text-info">
                            Todos los campos son obligatorios.
                        </p>}
                    </div>
                    {(user.rol.permisos.Grupos && user.rol.permisos.Grupos.editar) && <div className="text-right col-4 mb-3">
                        <div className="custom-control custom-switch">
                            <input type="checkbox" className="custom-control-input" id="customSwitch1" value={!this.state.readOnly} defaultChecked={!this.state.readOnly} name="readOnly" onChange={this.handleChangeEdit} />
                            <label className="custom-control-label text-primary" htmlFor="customSwitch1">Editar</label>
                        </div>
                    </div>}
                </div>}

                <form onSubmit={this.handleSubmit}>
                    <GroupForm 
                    required={true} 
                    handleValidName={this.handleValidName} 
                    readOnly={this.state.readOnly} 
                    setRemovePartner={this.setRemovePartner} 
                    handleRemovePartnerAfterCycle1={this.handleRemovePartnerAfterCycle1} 
                    resetform={this.state.resetform} 
                    data={this.data} 
                    partnersStatics={this.partnersStatics}
                    setData={this.setData} 
                    handleSpinner={this.handleSpinner} 
                    getGroup={this.getGroup} 
                    setActiveNewCycle={this.setActiveNewCycle} 
                    handleOpenEndCycle={this.handleOpenEndCycle} 
                    handleRestructure={this.handleRestructure} 
                    handleActiveGroup={this.handleActiveGroup}
                    id_grupo={this.props.match.params.id_grupo}
                    id_diccionario={this.props.match.params.id_diccionario} />
                    
                    {!this.state.readOnly && <>
                    <div className="form-group">
                     {  /*
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="grupo_reducido" name="grupo_reducido" value={this.state.grupo_reducido} defaultChecked={this.state.grupo_reducido} onChange={this.handleChange} />
                            <label className="form-check-label" htmlFor="grupo_reducido">
                                Permitir grupo reducido (menos de 3 clientas)
                            </label>
                        </div>
                      */ }
                    </div>
                    <br/>
                    <button type="submit" className="btn btn-primary btn-lg">Guardar</button>
                    </>}
                </form>

                <div className="modal fade" id="removePartnerModal" tabIndex="-1" role="dialog" aria-labelledby="removePartnerModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <form onSubmit={this.handleRemovePartner} className="modal-content mb-0">
                            <div className="modal-header">
                                <h5 className="modal-title" id="removePartnerModalLabel">Quitar clienta del grupo</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <small className="text-muted">Clienta seleccionada</small>
                                <p>
                                    {this.state.partnerRemove.nombre} {this.state.partnerRemove.apellido_paterno} {this.state.partnerRemove.apellido_materno}
                                </p>
                                <hr />
                                <div className="row">
                                    <div className="col-md-4">
                                        <label>Status</label>
                                        <div className="form-check mb-2">
                                            <input className="form-check-input" type="radio" name="status" id="status_activo" value="ACTIVO" checked={this.state.status === 'ACTIVO'} onChange={this.handleChange} required />
                                            <label className="form-check-label" htmlFor="status_activo">
                                                Activa
                                            </label>
                                        </div>
                                        <div className="form-check mb-2">
                                            <input className="form-check-input" type="radio" name="status" id="status_baja" value="BAJA_VOLUNTARIA" checked={this.state.status === 'BAJA_VOLUNTARIA'} onChange={this.handleChange} required />
                                            <label className="form-check-label" htmlFor="status_baja">
                                                Baja voluntaria
                                            </label>
                                        </div>
                                        <div className="form-check mb-2">
                                            <input className="form-check-input" type="radio" name="status" id="status_baja_castigada" value="BAJA_CASTIGADA" checked={this.state.status === 'BAJA_CASTIGADA'} onChange={this.handleChange} required />
                                            <label className="form-check-label" htmlFor="status_baja_castigada">
                                                Baja castigada
                                            </label>
                                        </div>
                                        <div className="form-check mb-2">
                                            <input className="form-check-input" type="radio" name="status" id="status_baja_rechazada" value="RECHAZADO" checked={this.state.status === 'RECHAZADO'} onChange={this.handleChange} required />
                                            <label className="form-check-label" htmlFor="status_baja_rechazada">
                                                Rechazada
                                            </label>
                                        </div>
                                        <div className="form-check mb-2">
                                            <input className="form-check-input" type="radio" name="status" id="status_baja_morocidad" value="MOROSIDAD" checked={this.state.status === 'MOROSIDAD'} onChange={this.handleChange} required />
                                            <label className="form-check-label" htmlFor="status_baja_morocidad">
                                                Morosidad
                                            </label>
                                        </div>
                                        <div className="form-check mb-2">
                                            <input className="form-check-input" type="radio" name="status" id="status_baja_fallecida" value="FALLECIDA" checked={this.state.status === 'FALLECIDA'} onChange={this.handleChange} required />
                                            <label className="form-check-label" htmlFor="status_baja_fallecida">
                                                Fallecida
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <label htmlFor="status_comentario">Comentario del status
                                            {(this.state.status === 'BAJA_VOLUNTARIA') && <span>*</span>}
                                            {(this.state.status === 'BAJA_CASTIGADA') && <span>*</span>}
                                            {(this.state.status === 'RECHAZADO') && <span>*</span>}
                                            {(this.state.status === 'MOROSIDAD') && <span>*</span>}
                                        </label>
                                        <textarea className="form-control text-uppercase" id="status_comentario" name="status_comentario" value={this.state.status_comentario} onChange={this.handleChange} rows="3" required={
                                            (this.state.status === 'BAJA_VOLUNTARIA') ||
                                            (this.state.status === 'BAJA_CASTIGADA') ||
                                            (this.state.status === 'RECHAZADO') ||
                                            (this.state.status === 'MOROSIDAD')
                                        } ></textarea>
                                    </div>
                                </div>
                                
                                
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                                <button type="submit" className="btn btn-primary">Guardar</button>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="modal fade" id="modaEndCycle" tabIndex="-1" role="dialog" aria-labelledby="modaEndCycle" aria-hidden="true">
                    <div className="modal-dialog">
                        <form onSubmit={this.handleEndCycle} className="modal-content mb-0">
                            <div className="modal-header">
                                <h5 className="modal-title" id="modaEndCycle">Finalizar ciclo</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="card-body">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label>Status de grupo</label>
                                                <div className="form-check mb-2">
                                                    <input className="form-check-input" type="radio" name="status" id="status_ACTIVO" value="activo" checked={this.state.status==='activo'} onChange={this.handleChange} required />
                                                    <label className="form-check-label" htmlFor="status_ACTIVO">
                                                        Activo
                                                    </label>
                                                </div>
                                                <div className="form-check mb-2">
                                                    <input className="form-check-input" type="radio" name="status" id="status_BAJA" value="baja" checked={this.state.status==='baja'} onChange={this.handleChange} required />
                                                    <label className="form-check-label" htmlFor="status_BAJA">
                                                        Baja
                                                    </label>
                                                </div>
                                                <div className="form-check mb-2">
                                                    <input className="form-check-input" type="radio" name="status" id="status_RECHAZADO" value="rechazado" checked={this.state.status==='rechazado'} onChange={this.handleChange} required />
                                                    <label className="form-check-label" htmlFor="status_RECHAZADO">
                                                        Rechazado
                                                    </label>
                                                </div>
                                                <div className="form-check mb-2">
                                                    <input className="form-check-input" type="radio" name="status" id="status_MOROSIDAD" value="morosidad" checked={this.state.status==='morosidad'} onChange={this.handleChange} required />
                                                    <label className="form-check-label" htmlFor="status_MOROSIDAD">
                                                        Morosidad
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <div className="form-group">
                                                    <label htmlFor="status_comentario">Escribe un comentario (Opcional)</label>
                                                    <textarea className="form-control text-uppercase" id="status_comentario" name="status_comentario" value={this.state.status_comentario} onChange={this.handleChange} rows="3"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <br />
                                
                                <label><b>Modifica el status de las clientas del grupo</b></label>
                            
                                {(!_.isEmpty(this.data_original.Socios) && this.data_original.Socios.length) && this.data_original.Socios.map((socio, index) => this.state.status_socios[index] && <div className="card mb-3" key={index}>
                                    <div className="card-body">
                                        <p>
                                            {socio.nombre} {socio.apellido_paterno} {socio.apellido_materno}
                                        </p>
                                        <hr />
                                        <div className="row">
                                            <div className="col-md-4">
                                                <label>Status</label>
                                                <div className="form-check mb-2">
                                                    <input className="form-check-input" type="radio" name={['status',index]} id={`status_activo_${index}`} value="ACTIVO" checked={this.state.status_socios[index].status === 'ACTIVO'} onChange={this.handleChangeStatusSocios} required />
                                                    <label className="form-check-label" htmlFor={`status_activo_${index}`}>
                                                        Activa
                                                    </label>
                                                </div>
                                                <div className="form-check mb-2">
                                                    <input className="form-check-input" type="radio" name={['status',index]} id={`status_baja_${index}`} value="BAJA_VOLUNTARIA" checked={this.state.status_socios[index].status === 'BAJA_VOLUNTARIA'} onChange={this.handleChangeStatusSocios} required />
                                                    <label className="form-check-label" htmlFor={`status_baja_${index}`}>
                                                        Baja voluntaria
                                                    </label>
                                                </div>
                                                <div className="form-check mb-2">
                                                    <input className="form-check-input" type="radio" name={['status',index]} id={`status_baja_castigada_${index}`} value="BAJA_CASTIGADA" checked={this.state.status_socios[index].status === 'BAJA_CASTIGADA'} onChange={this.handleChangeStatusSocios} />
                                                    <label className="form-check-label" htmlFor={`status_baja_castigada_${index}`}>
                                                        Baja castigada
                                                    </label>
                                                </div>
                                                <div className="form-check mb-2">
                                                    <input className="form-check-input" type="radio" name={['status',index]} id={`status_baja_rechazada_${index}`} value="RECHAZADO" checked={this.state.status_socios[index].status === 'RECHAZADO'} onChange={this.handleChangeStatusSocios} required />
                                                    <label className="form-check-label" htmlFor={`status_baja_rechazada_${index}`}>
                                                        Rechazada
                                                    </label>
                                                </div>
                                                <div className="form-check mb-2">
                                                    <input className="form-check-input" type="radio" name={['status',index]} id={`status_baja_morocidad_${index}`} value="MOROSIDAD" checked={this.state.status_socios[index].status === 'MOROSIDAD'} onChange={this.handleChangeStatusSocios} />
                                                    <label className="form-check-label" htmlFor={`status_baja_morocidad_${index}`}>
                                                        Morosidad
                                                    </label>
                                                </div>
                                                <div className="form-check mb-2">
                                                    <input className="form-check-input" type="radio" name={['status',index]} id={`status_baja_fallecida_${index}`} value="FALLECIDA" checked={this.state.status_socios[index].status === 'FALLECIDA'} onChange={this.handleChangeStatusSocios} required />
                                                    <label className="form-check-label" htmlFor={`status_baja_fallecida_${index}`}>
                                                        Fallecida
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <label htmlFor={`status_comentario${index}`}>Comentario del status
                                                    {(this.state.status_socios[index].status === 'BAJA_VOLUNTARIA') && <span>*</span>}
                                                    {(this.state.status_socios[index].status === 'BAJA_CASTIGADA') && <span>*</span>}
                                                    {(this.state.status_socios[index].status === 'RECHAZADO') && <span>*</span>}
                                                    {(this.state.status_socios[index].status === 'MOROSIDAD') && <span>*</span>}
                                                </label>
                                                <textarea className="form-control text-uppercase" id={`status_comentario${index}`} name={['status_comentario',index]} value={this.state.status_socios[index].status_comentario} onChange={this.handleChangeStatusSocios} rows="3" required={
                                                    (this.state.status_socios[index].status === 'BAJA_VOLUNTARIA') ||
                                                    (this.state.status_socios[index].status === 'BAJA_CASTIGADA') ||
                                                    (this.state.status_socios[index].status === 'RECHAZADO') ||
                                                    (this.state.status_socios[index].status === 'MOROSIDAD')
                                                } ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>)}
                            </div>
                            
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancelar</button>
                                <button type="submit" className="btn btn-primary">Guardar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        );
    }
}

export default GroupEdit;

const styles = {
    contentImgGroup: {
        minHeight: 200,
        maxHeight: 400
    }
};